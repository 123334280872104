import request from "@/libs/http";

export const queryLogisticsList = (params) => request.post('/oms/query-logistics-list', params) //查询物流公司
export const confirmShipment = (params) => request.post('/pos/visitor-booking/confirm-shipment', params) //客订发货
export const deptProcessApply = (params) => request.post('/pos/visitor-booking/dept-process-apply', params) //客订申请处理
export const saveVisitorApply = (params) => request.post('/pos/visitor-booking/save-apply-info', params) //保存客订申请
export const visitorBooking = (params) => request.post('/pos/visitor-booking/match-agency-ship-dept', params) //客订智能匹配
export const statisticsApplyCount = (params) => request.post('/pos/visitor-booking/statistics-apply-count', params) //客订申请统计
export const otherStoreRecord = (params) => request.post('/pos/visitor-booking//visitor-booking-other-dept-recode-list', params) //查询其他店铺客订申请
export const queryVisitorDetail = (params) => request.post('/pos/visitor-booking/visitor-booking-recode-detail', params) //查询客订详情
export const ourStoreRecord = (params) => request.post('/pos/visitor-booking/visitor-booking-this-dept-recode-list', params) //查询本店铺客订申请
export const sureGetGoods = (params) => request.post('/pos/visitor-booking/sign-erp-visitor-booking-apply-info', params) //确认收货
export const visitorCancelApplay = (params) => request.post('/pos/visitor-booking/cancel-apply', params) //取消申请
export const posVisitorBooking = (params) => request.post('/pos/visitor-booking/query-enable-apply_goods-list', params) //查询可选的货品信息
export const queryDeptAddress = (params) => request.post('/user/query-dept-address', params) //查询店铺地址
export const visitorCheckApply = (params) => request.post('/pos/visitor-booking/pre-valid-apply', params) //客订申请校验
export const queryMarketing = (params) => request.post('/pos/visitor-booking/query-visitor-booking-goods-stock', params) //查询当前店铺申请货品库存
export const queryBookingApplyPojo = (params) => request.post('/pos/after-sales/query-booking-apply-pojo', params) //查询当前店铺申请货品库存
export const visitorBookingRematchDept = (params) => request.post('/pos/visitor-booking/rematch-dept', params)
